* {
  color-adjust: exact !important;
  -webkit-print-color-adjust: exact !important;
}

.page-layout--sidebar__container .page-aside {
  display: none;
}

.main-menu {
  display: none;
}

.utility-nav {
  display: none;
}

  .main-header-container {
  height: auto !important;
}

.listing-header__subhead {
  border-bottom: 2px solid #000;
  font-family: "Droid Serif", serif;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 0.8;
  margin-bottom:  10px;
  padding: 30px 0;
  text-transform: uppercase;
}

.listing-header__subhead br {
  display: none;
}

.listing-header__heading {
  display: none;
}

.listing-event {
  padding: 30px 0;
}

.accordion-body {
  display: none;
}

.listing-event__meta {
  display: grid;
  grid-template-columns: 100px 1fr;
  width: 100%;
}

.listing-event__meta .show-for-large.column {
  display: none;
  position: absolute;
  visibility: hidden;
}

.listing-event__meta .h4 {
  display: none;
}

.listing-event__meta .accordion-meta {
  margin: 0;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, sans-serif;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.listing-event__meta .accordion-meta time {
  font-size: 12px;
  letter-spacing: 1px;
  font-family: "Droid Serif", serif;
  text-transform: lowercase;
  font-weight: 700;
}

.listing-event__meta .heading-link {
  color: #000000;
  font-family: "Droid Serif", serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  margin: 0;
  text-rendering: optimizeLegibility;
}

.listing-event__meta .column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80vw;
}

.listing-event__meta .column.large-2 {
  width: 10vw;
  display: inline-block;
}

.listing-event__meta a {
  display: inline-block;
  text-decoration: none;
}

.listing-event__meta .row {
  display: inline-block;
  width: 50vw;
}

.header-title {
  background: transparent url('./assets/img/dccouncil_typemark.svg') no-repeat left center;
  background-size: contain;
  display: block;
  height: 30px;
  transition: all 300ms ease 100ms;
  width: 175px;
}

.header-logo {
  background: transparent url('./assets/img/dc_seal_black.png') no-repeat top center;
  background-size: contain;
  display: block;
  height: 75px;
  margin: 0;
  order: 2;
  overflow: hidden;
  position: relative;
  text-indent: -999%;
  transition: 300ms ease all;
  width: 75px;
}

.header-title span {
  display: none;
}

.header-logo-container a {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.mobile-nav-trigger {
  display: none;
}

.site-footer {
  display: none;
  position: absolute;
  visibility: hidden;
}

.search-overlay {
  display: none;
}

.mobile-nav-overlay {
  display: none;
}

.events-pagination {
  display: none;
}

.column-header {
  display: block;
  color: #929292;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, sans-serif;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.listing-header__weekday {
  display: inline-block;
  margin-right: 5px;
}

.accordion {
  border-bottom: 1px solid #E0DFD5;
  margin-bottom: 20px;
  padding: 20px 0;
}

.accordion-header {
  margin-bottom: 10px;
}

.accordion:not(:first-of-type) .column-header {
  display: none;
}
